import {HttpClient, HttpContext} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AppConfig} from '../app.config';
import {EventLog} from '../domain/models/event-log/event-log';
import {HTTP_SUPPRESS_LOGIN_DIALOG, HTTP_SUPPRESS_TOASTR} from '../custom-http-interceptor';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EventLogProvider {
  constructor(private http: HttpClient) {
  }

  createEventLog(request: EventLog): Observable<void> {
    return this.http.post<void>(
      `${AppConfig.getApiUrl()}/event-logs`,
      request,
      {
        headers: {'auth-key': AppConfig.authKey},
        context: new HttpContext().set(HTTP_SUPPRESS_LOGIN_DIALOG, true).set(HTTP_SUPPRESS_TOASTR, true),
      },
    );
  }
}
