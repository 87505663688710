<div class="order-item">
  <ng-container *ngIf="order else loading">
    <div class="order-item__details">
      <div>
        <h3 class="order-item__title">
          {{ 'ORDERS.purchase' | translate }} #{{ order.number }}
        </h3>
        <div class="order-item__subtitle">
          <!-- TODO Add num. items. See SCAN-2732 -->
        </div>
      </div>
      <div *ngIf="isPaid" [innerHtml]="paidAtString" class="order-item__date"></div>
    </div>

    <div class="order-item__spacer">
      <svg width="100%" height="1">
        <line x1="0" y1="0.5" x2="100%" y2="0.499972" stroke="#DFE7EB" stroke-dasharray="6 6"></line>
      </svg>
    </div>

    <div class="order-item__summary">
      <div class="order-item__status">
        <div class="order-item__paid">
          {{ 'RECEIPT.paid' | translate }}
        </div>
        <div *ngIf="hasPendingDelivery" class="order-item__delivery-pending">
          {{ 'RECEIPT.delivery' | translate }}
        </div>
      </div>
      <div class="order-item__sum">
        {{ toPrice(order.sum) | currency:currencyCode:'symbol-narrow': '':cultureName }}
      </div>
    </div>
  </ng-container>
</div>

<ng-template #loading>
  <app-gradient-loader style="margin-bottom: 20px;  width: 80%"></app-gradient-loader>
  <app-gradient-loader></app-gradient-loader>
</ng-template>
