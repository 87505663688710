import {Inject, InjectionToken, ModuleWithProviders, NgModule} from '@angular/core';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateCompiler, TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {TranslateMessageFormatCompiler} from 'ngx-translate-messageformat-compiler';
import {registerLocaleData} from '@angular/common';
import localeNb from '@angular/common/locales/nb';
import localeSe from '@angular/common/locales/sv';
import localeDe from '@angular/common/locales/de';
import localeFi from '@angular/common/locales/fi';

registerLocaleData(localeNb, 'nb-NO');
registerLocaleData(localeNb, 'no');
registerLocaleData(localeNb, 'nn');

registerLocaleData(localeSe, 'sv-SE');
registerLocaleData(localeSe, 'sv-FI');
registerLocaleData(localeSe, 'sv');

registerLocaleData(localeDe, 'de-DE');
registerLocaleData(localeDe, 'de');

registerLocaleData(localeFi, 'fi-FI');
registerLocaleData(localeFi, 'fi');

@NgModule({
  imports: [
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => new TranslateHttpLoader(http, './assets/i18n/', '.json'),
        deps: [HttpClient],
      },
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler,
      },
    }),
  ],
  exports: [TranslateModule],
})
export class I18nModule {
  static forRoot(defaultLanguage: string): ModuleWithProviders<I18nModule> {
    return {
      ngModule: I18nModule,
      providers: [
        {
          provide: 'DEFAULT_LANGUAGE',
          useValue: defaultLanguage,
        },
      ],
    };
  }

  constructor(@Inject('DEFAULT_LANGUAGE') private defaultLanguage: string,
              private translateService: TranslateService,
  ) {
    this.translateService.addLangs(['en', 'no', 'sv', 'de']);
    this.translateService.setDefaultLang(this.defaultLanguage);
    this.translateService.use(this.getBrowserLanguage());
  }

  // Must return a valid locale ID of 'xx-XX' (e.g. 'en-US', 'nb-NO')
  getBrowserLocale(): string {
    const browserLang = this.translateService.getBrowserCultureLang();

    switch (browserLang) {
      case 'nb':
      case 'nn':
      case 'no':
        return 'nb-NO';
      case 'sv':
        return 'sv-SE';
      case 'de':
        return 'de-DE';
      case 'da':
        return 'da-DK';
      case 'fi':
        return 'fi-FI';
    }

    return 'en-US';
  }

  // Must return a valid language code of 'xx' that maps to available languages (e.g. 'en', 'no', and NOT 'nb')
  getBrowserLanguage(): string {
    let browserLang = this.translateService.getBrowserLang();

    switch (browserLang) {
      case 'nb':
      case 'nn':
      case 'no':
        browserLang = 'no';
        break;
      case 'sv':
        browserLang = 'sv';
        break;
      case 'de':
        browserLang = 'de';
        break;
    }

    return this.translateService.getLangs().includes(browserLang) ? browserLang : 'en';
  }

  // Must return a valid country code of 'XX' (e.g. 'NO', 'SE')
  getCountryCode(): string {
    const browserLocale = this.getBrowserLocale();
    return browserLocale.split('-')[1].toUpperCase();
  }
}

/** This token is used to inject the language ID into components. (Similar to Angular´s LOCAL_ID implementation)
* @see getBrowserLanguage()
*/
export const LANGUAGE_ID = new InjectionToken<string>('LANGUAGE_ID');

/** This token is used to inject the country code into components.
 * @see getCountryCode()
 */
export const COUNTRY_CODE = new InjectionToken<string>('COUNTRY_CODE');
