export interface EventLog {
  group: EventGroup;
  type: string;
  data: string;
  storeId?: string;
  doorId?: string;
  orderId?: string;
  occurredAt?: string; // 2025-02-20T08:37:51.709
}

export enum EventGroup {
  Door = 'door',
  Order = 'order',
  Product = 'product',
  Customer = 'customer',
}
