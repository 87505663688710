import {Component, EventEmitter, Inject, Input, LOCALE_ID, OnInit, Output} from '@angular/core';
import {Product} from '../../domain/models/product/product';
import {ImageUtils} from '../../utils/image.utils';
import {FormatUtils} from '../../utils/format.utils';
import {StoreProfile} from '../../domain/models/store/store-profile';
import {Campaign} from '../../domain/models/campaign/campaign';
import {CampaignUtils} from '../../utils/campaign.utils';
import {CampaignRuleScope} from '../../domain/models/campaign/rule';
import {Store} from '../../domain/models/store/store';
import {CartService} from '../../domain/cart.service';
import {Router} from '@angular/router';
import {DimensionAvailability} from '../../domain/models/product/dimension-availability';
import {DimensionAvailabilityService} from '../../domain/dimension-availability.service';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {AdjustmentTypeService} from '../../domain/adjustment-type.service';
import {ProductType} from '../../domain/models/product/product-microshop';
import {ForegroundPaths} from '../../app-routing.module';
import {ProductService} from '../../domain/product.service';
import {delay} from '../../utils/promise.utils';
import {bugsnagStartSpan} from '../../bugsnag';
import {WeightInputDialog} from '../../dialogs/weight-input/weight-input.dialog';
import {Gs1Utils} from '../../utils/gs1.utils';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.sass'],
})
export class ProductCardComponent implements OnInit {

  @Input() set product(value: ProductType | undefined) {
    this.setProduct(value);
  }
  get product(): ProductType | undefined {
    return this._product;
  }
  @Input() set store(value: Store | undefined) {
    this.setStore(value);
  }
  get store(): Store | undefined {
    return this._store;
  }

  @Output() onClick = new EventEmitter();
  @Input() hasShadow = false;
  @Input() currencyCode: string | undefined;

  cultureName: string;

  isBusy = false;
  campaigns?: Campaign[];
  profile?: StoreProfile;
  price?: string;

  dimensionAvailability: DimensionAvailability[] = [];
  availabilityCount: number | undefined;
  isStock = false;

  private _store?: Store;
  private _product?: ProductType;

  constructor(@Inject(LOCALE_ID) localeId: string,
              private cartService: CartService,
              private router: Router,
              private toastr: ToastrService,
              private translateService: TranslateService,
              private adjustmentService: AdjustmentTypeService,
              private dimensionAvailabilityService: DimensionAvailabilityService,
              private productService: ProductService,
              private dialog: MatDialog,
  ) {
    this.cultureName = localeId;
  }


  async ngOnInit() {
    this.profile = this.store?.storeProfile;
    if (this.store != null && this.product != null) {
      this.campaigns = CampaignUtils.campaignsForProduct(this.store, this.product?.id);
      this.setPrice();
    }
  }

  private setPrice() {
    if (this.product != null) {
      let storedAdjustmentTypeId = this.adjustmentService.getStoredTypeId(this.store!.handle);
      if (storedAdjustmentTypeId != null) {
        const price = this.product!.dimensions[0].prices.find(p => p.adjustmentType.id == storedAdjustmentTypeId);
        this.price = FormatUtils.toFriendlyPrice(price!.amount);
      } else {
        this.price = FormatUtils.toFriendlyPrice(this.product!.dimensions[0].prices[0].amount);
      }
    }
  }

  getProductImage(product: ProductType, size: number): string | undefined {
    return ImageUtils.getImageUrl(product.images[0], size, false);
  }

  getFallBackImage(): string {
    if (this.store?.storeProfile?.logoImage) {
      return this.store.storeProfile.logoImage.url;
    }

    return '/assets/images/noimage.png';
  }

  toFriendlyPrice(price: number): string {
    return FormatUtils.toFriendlyPrice(price);
  }

  getCheapestPrice(product: ProductType, campaigns: Campaign[]): number {
    return CampaignUtils.getCheapestPrice(product, campaigns);
  }

  hasProductCampaign(campaigns: Campaign[]): boolean {
    return campaigns.filter(c => c.rule.scope === CampaignRuleScope.Product).length > 0;
  }

  isQuickAddAvailable(): boolean {
    return this.product != null && this.store != null;
  }

  async quickAdd(storeHandle: string, $event: Event) {
    $event.stopPropagation();
    const span = bugsnagStartSpan('product-card:quickAdd');
    this.isBusy = true;

    if (!('description' in this._product!)) {
      // Fetch entire product if type is ProductMicroshop
      const product = await this.productService.getProductById(storeHandle, this._product!.id);
      this.setProduct(product);
    }

    let generatedScannedBarcode: string | undefined;
    if ((this.product as Product).isWeight && Gs1Utils.isValidProductBarcode((this.product as Product).dimensions[0]?.barcode)) {
      const weight = await this.dialog.open(WeightInputDialog, {
        data: {unit: 'grams'},
      }).afterClosed().toPromise();
      if (!weight) {
        this.isBusy = false;
        span?.end();
        return;
      }
      try {
        generatedScannedBarcode = Gs1Utils.generateWeightBarcode((this.product as Product).dimensions[0].barcode, weight);
      } catch (error) {
        this.toastr.error(error, undefined, {positionClass: 'toast-bottom-center'});
        this.isBusy = false;
        span?.end();
        return;
      }
    }

    const isShipmentDefault = (this.product as Product).fulfillmentOptions?.find(option => option.type === 'shipment')?.default;
    if (isShipmentDefault != undefined && !isShipmentDefault && this.product && this.store) {
      await this.router.navigate(ForegroundPaths.empty());
      await delay(80);
      await this.cartService.add(this.store.handle, this.product as Product, undefined, undefined, undefined, generatedScannedBarcode);
      this.isBusy = false;
      span?.end();
      return;
    }

    const itemCount = await this.cartService.getItemCount(storeHandle, this._product! as Product, this._product!.dimensions[0]);
    this.dimensionAvailability = await this.dimensionAvailabilityService.getStock(storeHandle, this._product?.id!);
    if (this.dimensionAvailability[0].trackStock) {
      this.isStock = true;
    }
    this.availabilityCount = this.dimensionAvailability[0].count;
     if (this.store != null && this.dimensionAvailability[0].trackStock && this.dimensionAvailability[0].count > itemCount || this.store != null && !this.dimensionAvailability[0].trackStock) {
      await this.router.navigate(ForegroundPaths.empty());
      await delay(80);
      await this.cartService.add(this.store!.handle, this.product! as Product, undefined, undefined, undefined, generatedScannedBarcode);
    } else {
      const message = await this.translateService.get('PRODUCTDETAILS.noStock').toPromise();
      this.toastr.error(message, undefined, {timeOut: 3000, easeTime: 100, positionClass: 'toast-bottom-center'});
    }
    this.isBusy = false;
    span?.end();
  }

  private setStore(value: Store | undefined) {
    this._store = value;
    this.profile = value?.storeProfile;
    if (value != null && this.product != null) {
      this.campaigns = CampaignUtils.campaignsForProduct(value, this.product?.id);
      this.setPrice();
    }
  }

  private setProduct(value: ProductType | undefined) {
    this._product = value;
    if (this.store != null && value != null) {
      this.campaigns = CampaignUtils.campaignsForProduct(this.store, value.id);
      this.setPrice();
    }
  }
}
