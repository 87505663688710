<div (click)="login()">
  <img *ngIf="language == 'no'"
       src="assets/images/log_in_with_vipps_rect_250_NO.svg"
       width="240"
       height="50"
       alt="Log inn med Vipps"
  >
  <img *ngIf="language != 'no'"
       src="assets/images/log_in_with_vipps_rect_250_EN.svg"
       width="240"
       height="50"
       alt="Log in with Vipps"
  >
</div>
