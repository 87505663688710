import {EventLogProvider} from '../transport/event-log.provider';
import {EventGroup, EventLog} from './models/event-log/event-log';
import {environment} from '../../environments/environment';
import {LocalStorageService} from 'ngx-webstorage';
import Bugsnag from '@bugsnag/js';
import {CustomerService} from './customer.service';
import {httpUtils} from '../utils/http.utils';
import {HttpStatusCode} from '@angular/common/http';
import {from, Subject, Subscription} from 'rxjs';
import {debounceTime, filter, mergeMap, take, toArray} from 'rxjs/operators';
import {sort} from '../utils/rxPipes.utils';
import {Injectable, OnDestroy} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class EventLogService implements OnDestroy {
  readonly eventLogsStorageKey = `eventlogs_${environment.apiUrl}`;
  readonly maxStorageAgeMs = 1000 * 60 * 60 * 24 * 3;

  private _customerSubscription?: Subscription;
  private _lastKnownCustomerId: string | null = null;
  private _queueSubmissionStream: Subject<void> | null = new Subject();
  private _queueChainProcess?: Subscription;

  constructor(private eventLogProvider: EventLogProvider,
              private localStorageService: LocalStorageService,
              private customerService: CustomerService,
  ) {
    this._queueSubmissionStream?.pipe(debounceTime(100))
      .subscribe(() => this.submitQueuedEventLogs());

    this._customerSubscription = this.customerService.customerId$
      .subscribe((customerId) => {
        if (customerId != null) {
          this._lastKnownCustomerId = customerId.value;
          this._queueSubmissionStream?.next();
        }
      });
  }

  ngOnDestroy() {
    this._queueSubmissionStream?.complete();
    this._queueSubmissionStream?.unsubscribe();
    this._queueSubmissionStream = null;
    this._customerSubscription?.unsubscribe();
  }

  async submitQueuedEventLogs() {
    const currentCustomerId = this._lastKnownCustomerId;
    if (currentCustomerId == null) {
      // Defer submission until we have a customerId
      return;
    }

    const storageEventLogs = this._getFromLocalStorage();
    if (storageEventLogs.length === 0) {
      return;
    }

    this._queueChainProcess?.unsubscribe();
    this._queueChainProcess = from(storageEventLogs).pipe(
      filter(event => event.timestamp > Date.now() - this.maxStorageAgeMs),
      sort((a, b) => b.timestamp - a.timestamp),
      take(10), // Try not to DDOS ourselves by limiting to the 10 latest events
      mergeMap(async (event) => {
        if (event.customerId != currentCustomerId) {
          // Defer these until we get the correct customerId (hopefully)
          return event;
        }
        try {
          await this.submitEventLog(event.eventLog);
          return null;
        } catch (error) {
          if (httpUtils(error).isStatus(HttpStatusCode.BadRequest)) {
            return null; // Discard erroneous event log
          }
          // eslint-disable-next-line no-console
          console.warn(`Failed to submit event log`, error);
          return event;
        }
      }),
      filter((event): event is StorageEventLog => event != null),
      toArray(),
    ).subscribe(remaining => this.localStorageService.store(this.eventLogsStorageKey, remaining));
  }

  async submitEventLog(request: EventLog) {
    try {
      await this.eventLogProvider.createEventLog(request).toPromise();
    } catch (e) {
      Bugsnag.notify(
        {name: `Failed to submit event log (${e.status})`, message: `${e.error.detail}: ${e.error.title}`},
        event => event.addMetadata('Response', e),
      );
      throw e;
    }
  }

  async logStoreQrScanned(customerId: string, storeId: string, url: string) {
    const eventLog: EventLog = {
      storeId,
      group: EventGroup.Customer,
      type: 'scannedQr',
      data: url,
      occurredAt: new Date().toISOString(),
    };
    this._writeToLocalStorage(new StorageEventLog(customerId, eventLog));
    this._queueSubmissionStream?.next();
  }

  async logDoorQrScanned(customerId: string, doorId: string, url: string) {
    const eventLog: EventLog = {
      doorId,
      group: EventGroup.Customer,
      type: 'scannedQr',
      data: url,
      occurredAt: new Date().toISOString(),
    };
    this._writeToLocalStorage(new StorageEventLog(customerId, eventLog));
    this._queueSubmissionStream?.next();
  }

  private _getFromLocalStorage(): StorageEventLog[] {
    try {
      return this.localStorageService.retrieve(this.eventLogsStorageKey) as StorageEventLog[] ?? [];
    } catch (e) {
      this.localStorageService.clear(this.eventLogsStorageKey);
      return [];
    }
  }

  private _writeToLocalStorage(storageEventLog: StorageEventLog) {
    const events = this._getFromLocalStorage();
    events.push(storageEventLog);
    this.localStorageService.store(this.eventLogsStorageKey, events);
  }
}

class StorageEventLog {
  constructor(
    readonly customerId: string,
    readonly eventLog: EventLog,
    readonly timestamp: number = Date.now(),
  ) {
  }
}
