import {Component, Inject, Input, LOCALE_ID, OnChanges, SimpleChanges} from '@angular/core';
import {Order} from '../../domain/models/order/order';
import {OrderUtils} from '../../utils/order.utils';
import {FormatUtils} from '../../utils/format.utils';

@Component({
  selector: 'app-order-item',
  templateUrl: './order-item.component.html',
  styleUrls: ['./order-item.component.sass'],
})
export class OrderItemComponent implements OnChanges {
  @Input() order?: Order;

  isPaid = false;
  hasPendingDelivery = false;
  paidAtString = '';

  currencyCode?: string;
  cultureName: string;

  constructor(@Inject(LOCALE_ID) localeId: string) {
    this.cultureName = localeId;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.order && this.order) {
      this.currencyCode = this.order.currencyCode;
      this.isPaid = OrderUtils.isPaid(this.order);
      this.hasPendingDelivery = this.isPaid && OrderUtils.isPendingDelivery(this.order);
      this.paidAtString = this.getPaidAtString();
    }
  }

  getPaidAtString() {
    const date = this.order ? OrderUtils.getPaidAtDate(this.order) : null;
    return date?.toLocaleTimeString().replace(/\s/g, '&nbsp;') ?? '-';
  }

  toPrice(price: number) {
    return FormatUtils.toFriendlyPrice(price);
  }
}
