import {from, Observable} from 'rxjs';
import {map, mergeMap, toArray} from 'rxjs/operators';

export function sort<T>(compareFn: (a: T, b: T) => number) {
  return (source: Observable<T>) => source.pipe(
    toArray(),
    map(array => array.slice().sort(compareFn)),
    mergeMap(sortedArray => from(sortedArray)),
  );
}
